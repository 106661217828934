import React, { useRef } from 'react';
import classNames from 'classnames';

import { formatDateShort } from '@common/utils/date';
import { OFF_SITE_DICE_TAG } from '@data/constants';
import {
	DiceEvent,
	ListingPreviewHeadingTag,
	PaginationData,
	ProgrammeLabels,
} from '@interfaces';

import { ListingCompact } from './ListingCompact';
import { ListingPagination } from './ListingPagination';
import { ListingThumbnail } from './ListingThumbnail';
import styles from './programme.module.scss';

const getIsDateHidden = (event: DiceEvent, previousEvent?: DiceEvent) => {
	return previousEvent
		? formatDateShort(event.date) === formatDateShort(previousEvent.date)
		: false;
};

interface ProgrammeProps {
	events: DiceEvent[] | null;
	className?: string;
	previewHeadingTag?: ListingPreviewHeadingTag;
	view?: string;
	paginationData?: PaginationData;
	isLoading?: boolean;
	showTicketAnchors?: boolean;
	programmeLabels?: ProgrammeLabels;
	serverNow?: number;
	diceAccessCode?: string;
}

export const Programme = ({
	events,
	className,
	previewHeadingTag,
	view,
	paginationData,
	isLoading = false,
	showTicketAnchors = false,
	programmeLabels,
	serverNow,
	diceAccessCode,
}: ProgrammeProps) => {
	const listRef = useRef<HTMLDivElement>(null);
	const isGrid = view === 'grid';

	const renderListing = (
		event: DiceEvent,
		index: number,
		allEvents: DiceEvent[],
		headingTag?: ListingPreviewHeadingTag,
	) => {
		const isElsewherePresents = event.tags?.includes(OFF_SITE_DICE_TAG);

		switch (view) {
			case 'grid':
				return (
					<ListingThumbnail
						diceAccessCode={diceAccessCode}
						event={event}
						headingTag={headingTag}
						isElsewherePresents={isElsewherePresents}
						key={event.id}
						programmeLabels={programmeLabels}
						serverNow={serverNow}
						showTicketAnchors={showTicketAnchors}
						sizes="(min-width: 56.25em) 33vw, (min-width: 37.5em) 50vw, 100vw"
					/>
				);
			default:
				return (
					<ListingCompact
						diceAccessCode={diceAccessCode}
						event={event}
						headingTag={headingTag}
						isDateHidden={getIsDateHidden(event, allEvents[index - 1])}
						isElsewherePresents={isElsewherePresents}
						key={event.id}
						programmeLabels={programmeLabels}
						serverNow={serverNow}
						showTicketAnchors={showTicketAnchors}
					/>
				);
		}
	};

	const renderEvents = () => {
		if (!events?.length) {
			return <p>no results...</p>;
		}

		const wrapperClass = classNames(styles.programme__list, 'list-unstyled', {
			[styles['programme__list--is-thumbnail']]: isGrid,
			[styles['programme__list--show-ticket-anchors']]: showTicketAnchors,
		});

		return (
			<div className={wrapperClass}>
				{events?.map((event, index, allEvents) =>
					renderListing(event, index, allEvents, previewHeadingTag),
				)}
			</div>
		);
	};

	return (
		<section
			className={classNames(className, styles.programme, {
				[styles['programme--is-loading']]: isLoading,
			})}
		>
			<div className={styles.programme__content} ref={listRef}>
				{renderEvents()}
				{paginationData ? <ListingPagination {...paginationData} /> : null}
			</div>
		</section>
	);
};
